import React, { useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Button } from 'merchant-portal-components';
import _ from 'lodash';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Radio,
  Checkbox,
  TextField,
  RadioGroup,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CustomizationContext } from '../../../utils/ClientCustomizationsProvider';

export const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
`;
export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 15px 32px;
  border-top: 1px solid #ebebeb;
`;

export const FormWrapper = styled.div`
  padding: 24px;
`;

export const ModalTitle = styled.div`
  font-family: 'Montserrat';
  font-size: 20px;
  color: #191818;
  text-align: left;
`;
export const LeftWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const ClearAllButton = styled.div`
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
`;
export const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const SectionTitle = styled.div`
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  margin: ${(props) => props.margin || '0'};
`;

const FilterOrdersModal = ({
  openFilter,
  setOpenFilter,
  parentLocations,
  initialFilters,
  filters,
  setFilters,
  subBusinesses,
  setSubBusinesses,
  selectedParent,
  setSelectedParent,
  selectedChild,
  setSelectedChild,
}) => {
  const { isParentChildClient } = useContext(CustomizationContext);
  const handleParentSelection = (parents, setFieldValue) => {
    setSelectedParent(parents);
    let newSelectedChild = [];
    if (parents.length > 0) {
      parents.forEach((parent) => {
        parent.sub_businesses.forEach((subBusiness) => {
          newSelectedChild.push({
            subBusinessId: subBusiness.id,
            subBusinessName: subBusiness.display_name,
            parentName: parent.display_name,
          });
        });
      });
    }
    setSubBusinesses(newSelectedChild);
    if (parents.length > 0) {
      const newSelectedParentNames = parents.map(
        (parent) => parent.display_name
      );
      let newSelectedChild = [];
      if (selectedChild.length > 0) {
        selectedChild.forEach((child) => {
          if (newSelectedParentNames.includes(child.parentName)) {
            newSelectedChild.push(child);
          }
        });
        setFieldValue('business_ids', newSelectedChild);
        setSelectedChild(newSelectedChild);
      }
    }
  };
  const toFindDuplicates = (arry) => {
    return arry.filter((item, index) => arry.indexOf(item) !== index);
  };
  const handleChildSelection = (
    currentSelectedChilds,
    setFieldValue,
    checked
  ) => {
    let newSelcetedLocations = [];
    if (isParentChildClient) {
      const currentSelectedChildsIDs = currentSelectedChilds.map(
        (sub) => sub.subBusinessId
      );
      const duplicateElements = toFindDuplicates(currentSelectedChildsIDs);
      currentSelectedChilds.forEach((subBusiness) => {
        if (!duplicateElements.includes(subBusiness.subBusinessId)) {
          newSelcetedLocations.push(subBusiness);
        }
      });
    } else {
      newSelcetedLocations = currentSelectedChilds;
    }
    setFieldValue('business_ids', newSelcetedLocations);
    setSelectedChild(newSelcetedLocations);
  };
  const renderGroup = (params) => [
    <div style={{ paddingLeft: '15px', paddingTop: '10px' }} key={params.key}>
      <span style={{ fontWeight: 600, color: 'black' }}>{params.group}</span>
    </div>,
    params.children,
  ];
  //////////////////////////////////
  const handleClearAll = (resetForm) => {
    setSelectedParent([]);
    setSelectedChild([]);
    setFilters(initialFilters);
    resetForm();
  };
  useEffect(() => {
    if (filters.business_ids.length === 0) {
      setSelectedParent([]);
    }
  }, [filters]);
  const handleSubmit = (values) => {
    setFilters(values);
    setOpenFilter(false);
  };
  const isLocationSelected = useCallback(
    (option) => {
      const locationItemList = selectedChild.map((buss) =>
        isParentChildClient ? buss.subBusinessName : buss.id
      );
      return locationItemList.includes(
        isParentChildClient ? option.subBusinessName : option.id
      );
    },
    [selectedChild]
  );
  return (
    <Dialog
      open={openFilter}
      onClose={() => setOpenFilter(false)}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle style={{ borderBottom: '1px solid #ebebeb' }}>
        <TitleWrapper>
          <ModalTitle>{'Filter orders by'}</ModalTitle>
          <div style={{ textAlign: 'center' }}>
            <i
              className='fal fa-times'
              style={{ cursor: 'pointer' }}
              onClick={() => setOpenFilter(false)}
            ></i>
          </div>
        </TitleWrapper>
      </DialogTitle>
      <Formik initialValues={filters} enableReinitialize>
        {({ values, handleChange, setFieldValue, resetForm }) => (
          <>
            <DialogContent style={{ padding: '0' }}>
              <FormWrapper>
                <SectionTitle marginBottom='12px'>Location</SectionTitle>
                {isParentChildClient && (
                  <Autocomplete
                    multiple
                    id='checkboxes-tags-demo'
                    value={selectedParent}
                    options={parentLocations}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.display_name}
                    onChange={(e, selectedLocations) => {
                      handleParentSelection(selectedLocations, setFieldValue);
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                          color='primary'
                        />
                        {option.display_name}
                      </React.Fragment>
                    )}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label='Parent Location(s)'
                      />
                    )}
                  />
                )}
                <Autocomplete
                  multiple
                  id='checkboxes-tags-demo'
                  disableCloseOnSelect
                  name='business_ids'
                  value={selectedChild}
                  options={
                    isParentChildClient ? subBusinesses : parentLocations
                  }
                  getOptionLabel={(option) =>
                    isParentChildClient
                      ? `${option.subBusinessName} (${option.parentName})`
                      : option.display_name
                  }
                  onChange={(e, selectedChild) => {
                    handleChildSelection(
                      selectedChild,
                      setFieldValue,
                      e.target.checked
                    );
                  }}
                  disableListWrap={false}
                  groupBy={(option) => option.parentName}
                  renderGroup={renderGroup}
                  renderOption={(option) => (
                    <div style={{ marginLeft: '15px' }}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={isLocationSelected(option)}
                        color='primary'
                      />
                      {isParentChildClient
                        ? option.subBusinessName
                        : option.display_name}
                    </div>
                  )}
                  style={{
                    width: '100%',
                    marginTop: '12px',
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label={
                        isParentChildClient
                          ? 'Child Location(s)'
                          : 'Location(s)'
                      }
                      placeholder=''
                    />
                  )}
                  disabled={isParentChildClient && !(selectedParent.length > 0)}
                />
                {selectedParent.length > 0 && selectedChild.length === 0 && (
                  <span style={{ color: 'red', fontSize: '13px' }}>
                    Please select child businesses.
                  </span>
                )}
                {/* <ErrorMessage name="business_ids" /> */}
                <SectionTitle margin='32px 0 0 0'> Status</SectionTitle>
                <FormGroup
                  style={{
                    paddingTop: '0',
                    paddingBottom: '0',
                    marginTop: '0',
                    marginBottom: '32px',
                    width: '170px',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isNew}
                        onChange={handleChange}
                        name='isNew'
                        color='primary'
                      />
                    }
                    label='New Order'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isInProgress}
                        onChange={handleChange}
                        name='isInProgress'
                        color='primary'
                      />
                    }
                    label='In Progress'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isFuture}
                        onChange={handleChange}
                        name='isFuture'
                        color='primary'
                      />
                    }
                    label='Future Order'
                  />
                </FormGroup>
                <SectionTitle>Order Type</SectionTitle>
                <FormControl
                  component='fieldset'
                  style={{
                    paddingTop: '0',
                    paddingBottom: '0',
                    marginTop: '0',
                    width: '180px',
                  }}
                >
                  <RadioGroup
                    aria-label='gender'
                    name='orderType'
                    value={values.orderType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value='all'
                      control={<Radio color='primary' />}
                      label='All Order Types'
                    />
                    <FormControlLabel
                      value='pickup'
                      control={<Radio color='primary' />}
                      label='Pickup'
                    />
                    <FormControlLabel
                      value='delivery'
                      control={<Radio color='primary' />}
                      label='Delivery'
                    />
                  </RadioGroup>
                </FormControl>
              </FormWrapper>
            </DialogContent>
            <ButtonsWrapper>
              <LeftWrapper>
                <ClearAllButton onClick={() => handleClearAll(resetForm)}>
                  Clear All
                </ClearAllButton>
              </LeftWrapper>
              <RightWrapper>
                <Button
                  type='submit'
                  onClick={() => handleSubmit(values)}
                  disabled={
                    _.isEqual(filters, values) ||
                    (selectedParent.length > 0 && selectedChild.length === 0)
                  }
                >
                  {'Save'}
                </Button>
              </RightWrapper>
            </ButtonsWrapper>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    parentLocations: state.businessLocationsList.businessLocationsOfMerchant,
  };
};
export default connect(mapStateToProps)(FilterOrdersModal);
