import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../combosAction';
import ComboArchiveForm from '../components/ComboArchiveForm';

const ComboArchiveContainer = ({
  comboId,
  archiveCombo,
  unArchiveCombo,
  getArchivedCombos,
  archivedCombos,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <ComboArchiveForm
        archivedCombos={archivedCombos}
        archiveCombo={archiveCombo}
        unArchiveCombo={unArchiveCombo}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        comboId={comboId}
        getArchivedCombos={getArchivedCombos}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    comboId: state.combosReducer?.editingCombo?.id,
    archivedCombos: state.combosReducer?.archivedCombos,
  };
};

export default connect(mapStateToProps, actions)(ComboArchiveContainer);
