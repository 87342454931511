import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Card from '../../../components/Card/Card';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { RenderSelect } from '../../../components/FormElements/FormikElements';
import { Field, ErrorMessage } from 'formik';
import { IconButton } from '@material-ui/core';
import FilterButton from '../../../components/FilterModal/FilterButton';
import LocationFilterModal from '../../../components/FilterModal/LocationFilterModal';
import FilterCustomerModal from '../../../components/FilterModal/FilterCustomerModal';
import { DisabledWrapper } from '../components/commonStyles';
import { CustomizationContext } from '../../../utils/ClientCustomizationsProvider';

const Wrapper = styled.div`
  display: grid;
  align-items: center;

  .mb25 {
    margin-bottom: 25px;
  }
  .mb25 .MuiSelect-select {
    border: 1px solid #cdcdcd;
    padding: 18px 15px;
    border-radius: 4px;
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border: 0px !important;
  }
`;
const CloseIcon = styled.i`
  position: absolute;
  color: #191818;
  top: 2px;
  right: 33px;
  cursor: pointer;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
`;
const WrapperInner = styled.div`
  display: grid;
  align-items: center;
  margin-left: 30px;
`;
const Subtitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #191818;
`;
const FullWrapper = styled.div`
  display: grid;

  .formSection {
    display: grid;
  }
`;
const NoteText = styled.div`
  font-size: 14px;
  color: #191818;
  margin-bottom: 10px;
`;
const NoteHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 5px;
`;
const NoteHeadNormal = styled.span`
  font-size: 17px;
  color: #191818;
  font-weight: normal;
`;
const SubHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 5px;
`;
const SubHeadDes = styled.div`
  font-size: 14px;
  color: #191818;
  margin-bottom: 10px;
  span {
    margin-left: 5px;
  }
`;
const SectionWrapper = styled.div`
  margin-top: 20px;
`;

const SegmentWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  margin-bottom: 20px;

  .react-select__control {
    background-color: white;
    border-radius: 6px;
  }

  .react-select__single-value {
    font-weight: 600;
    font-size: 14px;
  }

  .react-select__multi-value {
    background-color: #e6e6e6;
    border-radius: 15px;
    padding: 1px 3px;
    font-weight: 400;
    font-size: 14px;
  }
`;

const SlideThree = ({
  role,
  values,
  setFieldValue,
  audienceType,
  setAudienceType,
  segmentList,
  history,
  errors,
  orderTypesList,
}) => {
  const { isOfferPremiumClient } = useContext(CustomizationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);
  const handleClearClick = (event) => {
    setAudienceType('all');
    setFieldValue('segments', []);
    setFieldValue('customerList', []);
    setFieldValue('hasAssignedSegments', false);
    setFieldValue('hasAssignedCustomer', false);
  };
  useEffect(() => {
    const currOrderTypes = values.order_types;
    if (values.order_types.length === 0) {
      const newSelectedOrderTypes = orderTypesList.map((type) =>
        type.type.toLowerCase()
      );
      setSelectedOrderTypes(newSelectedOrderTypes);
    } else if (currOrderTypes.length > 0) {
      const filteredOrderTypes = orderTypesList?.filter((orderType) =>
        currOrderTypes?.includes(orderType.id)
      );
      const newSelectedOrderTypes = filteredOrderTypes.map((itemType) =>
        itemType.type.toLowerCase()
      );
      setSelectedOrderTypes(newSelectedOrderTypes);
    }
  }, [values?.order_types]);
  const handleNavigate = () => {
    history.push('/segments');
  };
  const handleOrderTypeChange = (e, type) => {
    const isSelected = e.target.checked;
    const selectedType = type;
    let newtOrderTypes = [...selectedOrderTypes];
    if (!isSelected) {
      newtOrderTypes = selectedOrderTypes.filter(
        (orderType) => orderType !== selectedType
      );
    } else {
      newtOrderTypes.push(selectedType);
    }
    setSelectedOrderTypes(newtOrderTypes);

    /// update valuse
    if (newtOrderTypes.length === orderTypesList.length) {
      setFieldValue('order_types', []);
    } else {
      const newOrderTypesIDs = [];
      orderTypesList.forEach((type) => {
        if (newtOrderTypes.includes(type.type.toLowerCase())) {
          newOrderTypesIDs.push(type.id);
        }
      });
      setFieldValue('order_types', newOrderTypesIDs);
    }
  };
  return (
    <div>
      <Wrapper>
        <Subtitle>Target Audience</Subtitle>
        <Card>
          <FullWrapper>
            <div className='formSection'>
              <DisabledWrapper disabled={!isOfferPremiumClient}>
                <NoteHead>
                  Specific audience(s)
                  <NoteHeadNormal>
                    (Optional) {!isOfferPremiumClient && 'Premium Feature'}
                  </NoteHeadNormal>
                </NoteHead>
                <NoteText>
                  Limit the offer’s visibility to specific Segments or customers
                  below. By default, there are no restrictions by Segment or
                  customers.
                </NoteText>
                <FormControl
                  variant='outlined'
                  style={{ margin: '0 0 20px 0', minWidth: '300px' }}
                >
                  <InputLabel id='demo-simple-select-label'>
                    {audienceType
                      ? 'Specific audience(s)'
                      : 'Select Specific audience(s)'}
                  </InputLabel>
                  <Select
                    labelId='select-audience-label'
                    label='Specific audience(s)'
                    id='select-audience'
                    value={audienceType}
                    onChange={(event) => {
                      if (event.target.value == 'customers') {
                        setAudienceType('customers');
                        setFieldValue('segments', []);
                        setFieldValue('hasAssignedCustomer', true);
                        setFieldValue('hasAssignedSegments', false);
                      } else if (event.target.value == 'segments') {
                        setAudienceType('segments');
                        setFieldValue('hasAssignedSegments', true);
                        setFieldValue('hasAssignedCustomer', false);
                        setFieldValue('customerList', []);
                      } else {
                        setAudienceType('all');
                        setFieldValue('hasAssignedSegments', false);
                        setFieldValue('hasAssignedCustomer', false);
                        setFieldValue('segments', []);
                        setFieldValue('customerList', []);
                      }
                    }}
                    // endAdornment={
                    //   audienceType && (
                    //     <IconButton
                    //       sx={{ display: audienceType !== '' ? 'none' : '' }}
                    //       onClick={handleClearClick}
                    //     >
                    //       <CloseIcon>X</CloseIcon>
                    //     </IconButton>
                    //   )
                    // }
                  >
                    <MenuItem value='all'>All customers</MenuItem>
                    <MenuItem value='segments'>
                      Limit to specific segments
                    </MenuItem>
                    <MenuItem value='customers'>
                      Limit to specific customers
                    </MenuItem>
                  </Select>
                </FormControl>
                {audienceType == 'segments' && (
                  <WrapperInner>
                    <NoteHead>Select customer segment(s)</NoteHead>
                    <NoteText>
                      You can create new Segments in the{' '}
                      <span
                        onClick={handleNavigate}
                        style={{ cursor: 'pointer', color: 'blue' }}
                      >
                        Segments
                      </span>{' '}
                      section of the portal.
                    </NoteText>
                    <FormControl>
                      <SegmentWrapper
                        onClick={(e) => {
                          e.stopPropagation();
                          //setSelectsegment()
                        }}
                      >
                        <Field
                          name='segments'
                          component={RenderSelect}
                          options={segmentList}
                          isMulti
                          setFieldValue={setFieldValue}
                          getOptionLabel={(business) => business.name}
                          getOptionValue={(business) => business}
                          valueKey='segment_id'
                          placeholder='Select Segments'
                          // errors={errors.segments}
                        />
                        <ErrorMessage name='errors.segments' />
                      </SegmentWrapper>
                    </FormControl>
                  </WrapperInner>
                )}
                {audienceType == 'customers' && (
                  <WrapperInner>
                    <NoteHead>Select customer(s)</NoteHead>
                    <FormControl>
                      <SegmentWrapper
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <FilterButton
                          message={`Selected Customers (${
                            values.customerList?.length || 0
                          })`}
                          height='55px'
                          setIsOpen={setIsOpen1}
                        />
                        <ErrorMessage name='errors.customerList' />
                      </SegmentWrapper>
                      {isOpen1 && (
                        <FilterCustomerModal
                          placeholder='Enter Customer Name, Email etc.'
                          searchLabel='Search by Customer Name, Email etc.'
                          filteredCustomers={values.customerList}
                          setFilteredCustomers={setFieldValue}
                          isOpen={isOpen1}
                          setIsOpen={setIsOpen1}
                          formField='customerList'
                          emptyIsEqualToAll={true}
                          isEmptyAccepted={false}
                        />
                      )}
                    </FormControl>
                  </WrapperInner>
                )}
              </DisabledWrapper>
              <NoteHead>
                Locations <NoteHeadNormal>(Optional)</NoteHeadNormal>
              </NoteHead>
              <NoteText>
                Limit the offer’s visibility to customers shopping at different
                locations below. By default, there are no restrictions by
                location.
              </NoteText>
              <FormControl>
                <FilterButton
                  message={
                    values?.offer_details?.businesses.length === 0
                      ? 'All Locations Selected'
                      : `Selected Locations (${values?.offer_details?.businesses.length})`
                  }
                  height='55px'
                  setIsOpen={setIsOpen}
                />
                {isOpen && (
                  <LocationFilterModal
                    placeholder='Enter Internal Location Name'
                    searchLabel='Search by Internal Location Name'
                    filteredLocations={values.offer_details.businesses}
                    setFilteredLocations={setFieldValue}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    formField='offer_details.businesses'
                    emptyIsEqualToAll={true}
                    isEmptyAccepted={false}
                  />
                )}
              </FormControl>
              <SectionWrapper>
                <NoteHead>
                  Order Types <NoteHeadNormal>(Optional)</NoteHeadNormal>
                </NoteHead>
                <NoteText>Assign this offer to a specific order Type</NoteText>
                {orderTypesList.map((orderType) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedOrderTypes.includes(
                          orderType.type.toLowerCase()
                        )}
                        color='primary'
                        disabled={
                          (selectedOrderTypes.includes(
                            orderType.type.toLowerCase()
                          ) &&
                            selectedOrderTypes.length === 1) ||
                          orderTypesList.length === 1
                        }
                        onChange={(e) =>
                          handleOrderTypeChange(e, orderType.type.toLowerCase())
                        }
                      />
                    }
                    label={orderType.type}
                  />
                ))}
              </SectionWrapper>
            </div>
          </FullWrapper>
        </Card>

        {role !== 'ROLE_LOYALTY_ADMIN' && (
          <>
            <Subtitle>Visibility</Subtitle>
            <Card>
              <SubHead>
                When is this offer visible to the target audience?
              </SubHead>
              <SubHeadDes>
                By default, all active offers will be displayed on the mobile
                app/web to the target audience, however there may be times when
                you do not want to display an offer on the mobile app/web.
                <span>
                  For example: In-store/ Scan offers which customers cannot
                  redeem through order ahead.
                </span>
              </SubHeadDes>
              <RadioGroup
                aria-labelledby='radio-buttons-group-label'
                value={
                  values.offer_details.is_visible == true ? 'true' : 'false'
                }
                id='radio-buttons-group'
                name='radio-buttons-group'
                onChange={(event) => {
                  setFieldValue(
                    'offer_details.is_visible',
                    event.target.value == 'true' ? true : false
                  );
                }}
              >
                <FormControlLabel
                  value='true'
                  control={<Radio />}
                  label='Always'
                />
                <FormControlLabel
                  value='false'
                  control={<Radio />}
                  label='Never'
                />
              </RadioGroup>
            </Card>
          </>
        )}
      </Wrapper>
    </div>
  );
};
export default SlideThree;
