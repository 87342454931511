import React, { Component, Fragment } from 'react';
import { change } from 'redux-form';
import * as actions from '../appFeedActions';
import { connect } from 'react-redux';
import {
  renderTextField,
  required,
  maxLength,
  urlValidation,
} from '../../../components/FormElements/ReduxForm';
import { AddCardWrapper } from './AppFeedElements';
import { Field } from 'redux-form';
import { ButtonRow, Button } from 'merchant-portal-components';
import { Select } from 'merchant-portal-components';
import ListSearchSelect from './ListSearchSelect';
import getOffers from '../../HOCs/Offers/getOffers';
import getCombos from '../../HOCs/Combos/getCombos';
import MediaModalContainer from '../../MediaModal/MediaModalContainer';
import { CustomizationContext } from '../../../utils/ClientCustomizationsProvider';
const maxLength50 = maxLength(50);
const maxLength256 = maxLength(256);
const maxLength512 = maxLength(512);

export class CardFormElements extends Component {
  static contextType = CustomizationContext;
  state = {
    cardActionType: 'webpage',
    selectedItem: false,
    initialCardActionLoaded: false,
    mediaModalVisible: false,
    value: false,
  };

  componentDidMount() {
    const itemIndex = this.props.card.charAt(this.props.card.length - 2);
    let cardAction = this.props.formValues.items[itemIndex]
      ? this.props.formValues.items[itemIndex].card_action
      : null;
    if (cardAction) {
      if (cardAction.includes('://products') && this.props.allProducts) {
        const selectedItemId = cardAction.substring(
          cardAction.lastIndexOf('/') + 1
        );
        const selectedItem = this.props.allProducts.find(
          ({ id }) => id == selectedItemId
        );
        this.setState({
          cardActionType: 'product',
          selectedItem,
          initialCardActionLoaded: true,
        });
      } else if (cardAction.includes('://offers') && this.props.allOffers) {
        const selectedItemId = cardAction.substring(
          cardAction.lastIndexOf('/') + 1
        );
        const selectedItem = this.props.allOffers.find(
          (offer) => offer?.offer_id === parseInt(selectedItemId)
        );
        this.setState({
          cardActionType: 'offer',
          selectedItem,
          initialCardActionLoaded: true,
        });
      } else if (cardAction.includes('://combos') && this.props.allCombos) {
        const selectedItemId = cardAction.substring(
          cardAction.lastIndexOf('/') + 1
        );
        const selectedItem = this.props.allCombos.find(
          ({ id }) => id == selectedItemId
        );
        this.setState({
          cardActionType: 'combo',
          selectedItem,
          initialCardActionLoaded: true,
        });
      } else if (
        cardAction.includes('://business') &&
        this.props.allBusinesses
      ) {
        const selectedItemId = cardAction.substring(
          cardAction.lastIndexOf('/') + 1
        );
        const selectedItem = this.props.allBusinesses.find(
          (business) => business?.id === parseInt(selectedItemId)
        );
        this.setState({
          cardActionType: 'business',
          selectedItem,
          initialCardActionLoaded: true,
        });
      } else {
        this.setState({ initialCardActionLoaded: true });
      }
    } else {
      this.setState({ initialCardActionLoaded: true });
      this.props.change('feedRowForm', `${this.props.card}.is_visible`, true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedItem, cardActionType } = this.state;
    const { change, card, appIdentifier } = this.props;
    const selectedName =
      selectedItem && cardActionType === 'offer'
        ? selectedItem?.template_name
        : selectedItem
        ? selectedItem.name
        : '';
    const selectedImageUrl =
      selectedItem && cardActionType === 'offer'
        ? selectedItem?.image_url
        : selectedItem
        ? selectedItem.image_url
        : '';
    const selectedId =
      selectedItem && cardActionType === 'offer'
        ? selectedItem?.offer_id
        : selectedItem
        ? selectedItem.id
        : '';
    if (prevState.initialCardActionLoaded) {
      if (prevState.selectedItem !== selectedItem) {
        if (selectedItem && cardActionType !== 'webpage') {
          const deepLink = `${
            appIdentifier.includes('_')
              ? appIdentifier.replaceAll('_', '')
              : appIdentifier
          }://${cardActionType}${
            cardActionType !== 'business' ? 's' : 'es/menu'
          }/${selectedId}`;
          change('feedRowForm', `${card}.card_action`, deepLink);
        }
        change('feedRowForm', `${card}.title`, selectedName);
        change('feedRowForm', `${card}.subtitle`, cardActionType);
        change('feedRowForm', `${card}.background_image_url`, selectedImageUrl);
      }
      if (prevState.cardActionType !== cardActionType) {
        change('feedRowForm', `${card}.card_action`, '');
        change('feedRowForm', `${card}.title`, '');
        change('feedRowForm', `${card}.subtitle`, '');
        change('feedRowForm', `${card}.background_image_url`, '');
      }
    }
    if (prevState.value !== this.state.value) {
      this.props.changed(this.state.value);
    }
  }

  selectItem = (selectedItem) => {
    this.setState({ selectedItem });
  };

  renderCardActionSelect = () => {
    const { cardActionType, selectedItem } = this.state;
    return (
      <div style={{ marginTop: '32px', marginBottom: '28px' }}>
        <ListSearchSelect
          changed={(v) => this.setState({ value: v })}
          listKey={cardActionType}
          selectItem={this.selectItem}
          selectedItem={selectedItem}
          {...this.props}
        />
      </div>
    );
  };

  render() {
    const { cardActionType } = this.state;
    const { card, light } = this.props;
    const { isMultipleAppFeedItemClient } = this.context;
    const cardActionTypeOptions = isMultipleAppFeedItemClient
      ? [
          { value: 'webpage', label: 'Show a link' },
          { value: 'product', label: 'Show a product' },
          { value: 'offer', label: 'Show an offer' },
          { value: 'combo', label: 'Show a combo' },
          { value: 'business', label: 'Show a Business' },
        ]
      : [
          { value: 'webpage', label: 'Show a link' },
          { value: 'product', label: 'Show a product' },
          { value: 'offer', label: 'Show an offer' },
          { value: 'combo', label: 'Show a combo' },
        ];

    return (
      <Fragment>
        <div>
          <Select
            options={cardActionTypeOptions}
            value={cardActionTypeOptions.find(
              ({ value }) => value === cardActionType
            )}
            onChange={({ value }) => {
              this.setState({
                cardActionType: value,
                selectedItem: false,
                value: true,
              });
            }}
            className='react-select-container'
            classNamePrefix='react-select'
            label='Card Action'
            topMod='-12px'
            zIndex={3}
            inLine
            whiteBackground
            light={light}
            name={card}
          />
          {cardActionType === 'webpage' ? (
            <div style={{ marginTop: '18px' }}>
              <Field
                name={`${card}.card_action`}
                component={renderTextField}
                type='url'
                label='Card URL'
                validate={[maxLength512]}
                placeholder='https://google.ca'
                light={light}
              />
            </div>
          ) : (
            this.renderCardActionSelect()
          )}
        </div>
        <Field
          label='Card Title'
          name={`${card}.title`}
          component={renderTextField}
          validate={maxLength50}
          type='text'
          light={light}
        />
        <Field
          label='Card Sub-Title'
          name={`${card}.subtitle`}
          component={renderTextField}
          validate={maxLength50}
          type='text'
          light={light}
        />

        <Field
          label='Card Description'
          name={`${card}.description`}
          component={renderTextField}
          validate={maxLength256}
          type='text'
          light={light}
          rows={3}
        />

        <Field
          label='Card Background Image URL'
          name={`${card}.background_image_url`}
          component={renderTextField}
          validate={[required, urlValidation]}
          type='text'
          light={light}
        />

        <Button
          type='button'
          primary
          onClick={() => this.setState({ mediaModalVisible: true })}
        >
          Gallery
        </Button>

        {this.state.mediaModalVisible && (
          <MediaModalContainer
            closeModal={() => this.setState({ mediaModalVisible: false })}
            selectProduct={(id, url) =>
              this.props.change(
                'feedRowForm',
                `${card}.background_image_url`,
                url
              )
            }
            name={`${card}.background_image_url`}
            buttonString='Option'
          />
        )}
      </Fragment>
    );
  }
}

const CardForm = (props) => {
  const { card, cancel, done, formValues, changed } = props;
  return (
    <AddCardWrapper>
      <CardFormElements
        card={card}
        light
        formValues={formValues}
        changed={changed}
        {...props}
      />
      <ButtonRow justifyContent='space-between'>
        <Button type='button' secondary small onClick={() => cancel()}>
          Cancel
        </Button>
        <Button type='button' small onClick={() => done()}>
          Done
        </Button>
      </ButtonRow>
    </AddCardWrapper>
  );
};

function mapStateToProps(state) {
  return {
    listByQuery: state.appFeed.listByQuery,
    requestingGetListByQuery: state.appFeed.requestingGetListByQuery,
    selectedListItemId: state.appFeed.selectedListItemId,
    feedRowFormIsVisible: state.appFeed.feedRowFormIsVisible,
    appIdentifier: state.accountReducer.appIdentifier,
    allProducts: state.productsReducer.allProducts,
  };
}

CardFormElements = getCombos(CardFormElements);
CardFormElements = getOffers(CardFormElements);

CardFormElements = connect(mapStateToProps, { change, ...actions })(
  CardFormElements
);

export default CardForm;
