import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { theme } from 'merchant-portal-components';
import { ThemeProvider, injectGlobal } from 'styled-components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Analytics from 'react-router-ga';
import store from './configureStore';
import { MuiThemeProvider } from '@material-ui/core/styles';
import 'react-widgets/dist/css/react-widgets.css';
import Full from './modules/Full/Full';
import Login from './modules/Account/Login';
import Signout from './modules/Account/Signout';
import NewUserLoginContainer from './modules/NewUserLoginPage/NewUserLoginContainer';
import Firebase, { FirebaseContext } from './modules/Firebase';
import './utils/css/icons.css';
import { muiTheme } from 'merchant-portal-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ResetPasswordRequest from './modules/Account/ResetPasswordRequest';
import ChangePassword from './modules/Account/ChangePassword';
import ReactGA from 'react-ga';
import { ClientCustomizationsProvider } from './utils/ClientCustomizationsProvider';
const styledColors = theme();
// const TRACKING_ID = 'UA-251806643-1'; // OUR_TRACKING_ID
const TRACKING_ID = ENV === 'production' ? 'UA-251806643-2' : 'UA-251806643-1';
ReactGA.initialize(TRACKING_ID);
injectGlobal`
  html, body {
    font-size: 16px;
    color: ${styledColors.darkBlue};
    font-family: 'Montserrat', sans-serif;
    background-color: #f2f2f2;
  }
  input[type="text"], textarea {
    -webkit-appearance: textfield;
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${styledColors.darkBlue};
  }

  #intercom-container {
    display: none !important;
  }
  .intercom-lightweight-app {
    display: none! important;
  }
`;

if (top.location.href == self.location.href) {
  ReactDOM.render(
    <Provider store={store}>
      <FirebaseContext.Provider>
        <ThemeProvider theme={theme(store.getState().appFeed.feedStyles)}>
          <MuiThemeProvider theme={muiTheme}>
            <ClientCustomizationsProvider>
              <BrowserRouter>
                <Analytics id={TRACKING_ID}>
                  <Switch>
                    <Route exact path='/' component={Login} />
                    <Route path='/login' component={Login} />
                    <Route path='/signout' component={Signout} />
                    <Route
                      path='/request-password-reset'
                      component={ResetPasswordRequest}
                    />
                    <Route
                      exact
                      path='/reset-password/:token'
                      component={ChangePassword}
                    />
                    <Route
                      exact
                      path='/new-user-login/:token'
                      name='New User Login'
                      component={NewUserLoginContainer}
                    />
                    <Full />
                  </Switch>
                </Analytics>
              </BrowserRouter>
            </ClientCustomizationsProvider>
          </MuiThemeProvider>
        </ThemeProvider>
      </FirebaseContext.Provider>
    </Provider>,
    document.getElementById('root')
  );
}
