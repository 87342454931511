import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Formik } from 'formik';
import { getOffers, submitPOSDiscountValuesOffers } from '../posAction';
import { LoadingBlueLarge } from 'merchant-portal-components';
import fetchOnParamChange from '../../../HOCs/fetchOnParamChange';
import OffersMappingTable from '../OffersMapping/component/OffersMappingTable';
const Wrapper = styled.div`
  width: 1000px;
  background: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-top: 24px;

  @media (max-width: 1313px) {
    width: 100%;
  }
`;

let OffersMappingContainer = ({
  meta,
  updateRequestParams,
  requestParams,
  offers,
  isRequestingGetOffers,
  getOffers,
  type,
  submitPOSDiscountValuesOffers,
  selectedValuePOS,
  setSelectedValuePOS,
  allOffers,
  posDiscountsMappingFields,
  posDiscountsFieldsNum,
}) => {
  const [initialValues, setInitialValues] = useState();
  const [offersModified, setOffersModified] = useState();

  const updateModifiedOffer = useCallback(() => {
    if (offers.length > 0) {
      const modifiedOffer = offers.map((offer, index) => ({
        ...offer,
        pos_mapping:
          offer.pos_mappings.length > 0
            ? Object.keys(offer.pos_mappings[0]).reduce((acc, key) => {
                acc[key] = offer.pos_mappings[0][key] || '';
                return acc;
              }, {})
            : '',
        index,
      }));
      const newInitialValues = {
        offers: modifiedOffer,
      };
      setOffersModified(modifiedOffer);
      setInitialValues(newInitialValues);
    }
  }, [offers]);
  useEffect(() => {
    updateModifiedOffer();
  }, [updateModifiedOffer]);
  return (
    <Formik initialValues={initialValues} enableReinitialize={true}>
      {({ values, handleReset, dirty, errors, setFieldValue, handleSave }) => (
        <Fragment>
          <Wrapper>
            {!isRequestingGetOffers ? (
              <OffersMappingTable
                meta={meta}
                requestParams={requestParams}
                updateRequestParams={updateRequestParams}
                dirty={dirty}
                handleReset={handleReset}
                values={values}
                initialValues={initialValues}
                type={type}
                offers={offersModified}
                getOffers={getOffers}
                submitPOSDiscountValuesOffers={submitPOSDiscountValuesOffers}
                initialRequestParams={initialRequestParams}
                selectedValuePOS={selectedValuePOS}
                setSelectedValuePOS={setSelectedValuePOS}
                allOffers={allOffers}
                posDiscountsMappingFields={posDiscountsMappingFields}
                posDiscountsFieldsNum={posDiscountsFieldsNum}
                setFieldValue={setFieldValue}
              />
            ) : (
              <LoadingBlueLarge />
            )}
          </Wrapper>
        </Fragment>
      )}
    </Formik>
  );
};

const initialRequestParams = {
  page_number: 1,
  page_size: 25,
  searchString: '',
};

OffersMappingContainer = fetchOnParamChange(
  OffersMappingContainer,
  getOffers,
  initialRequestParams
);

const mapStateToProps = (state) => {
  return {
    meta: state.posReducer.meta,
    isRequestingGetOffers: state.posReducer.isRequestingGetOffers,
    offers: state.posReducer.offers,
    allOffers: state.OfferEngineReducer.allOffersEngine,
    selectedValuePOS: state.posReducer.selectedValuePOS,
  };
};

export default connect(mapStateToProps, {
  getOffers,
  submitPOSDiscountValuesOffers,
})(OffersMappingContainer);
