import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';
import styled from 'styled-components';
import { Charts } from '../components/DashboardElements';
import { Button, LoadingBlueLarge } from 'merchant-portal-components';
import SummaryBar from './components/Summary';
import Transactions from './components/Transactions';
import Sales from './components/Sales';
import TransactionsPerHour from './components/TransactionsPerHour';
import OrderTypes from './components/OrderTypes';
import intervalOptions from '../utils/intervalOptions';
import FilterButton from '../../../components/FilterModal/FilterButton';
import LocationFilterModal from '../../../components/FilterModal/LocationFilterModal';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ReactGA from 'react-ga';
import DashboardDateSelection from '../components/DashboardDateSelection';
import moment from 'moment';

const paymentTypeOptions = [
  { value: 'all', label: 'All' },
  { value: 'mobile', label: 'Order Ahead' },
  { value: 'scan', label: 'Scan to Pay' },
];
const Title = styled.div`
  font-size: 35px;
  font-weight: bold;
  margin-top: 25px;
`;
const SearchWrapper = styled.div`
  margin: 25px 0;
  display: grid;
  grid-template-columns: 1.1fr 150px auto auto;
  grid-gap: 20px;
  align-items: flex-end;
  pointer-events: ${(props) => (!props.isDisabled ? 'none' : 'default')};
  opacity: ${(props) => (!props.isDisabled ? 0.5 : 1)};
`;
const ButtonWrapper = styled.div`
  margin-bottom: 5px;
`;
const MessageWrapper = styled.div`
  font-size: 28px;
  font-weight: 600;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class TransactionsDashboard extends Component {
  state = {
    business_ids: [],
    business_names: [],
    type: 'all',
    interval: intervalOptions[1],
    selectedLength: 0,
    isLocationFilterModalOpen: false,
    filteredPaymentTypeOptions: paymentTypeOptions,
    timePeriodType: 'fixed-period',
    startDate: null,
    endDate: null,
    hasError: false,
    message: '',
  };

  componentDidMount() {
    this.getAllTransactionDashboardData();
    track.page('dashboard-transactions');
    ReactGA.pageview(
      window.location.pathname,
      undefined,
      'Transactions Summary'
    );
    const allBusinessIDs = this.props.allBusinesses.map(
      (business) => business.id
    );
    this.setState({ business_ids: allBusinessIDs });

    const role = localStorage.getItem('role');
    if (role === 'ROLE_LOYALTY_ADMIN') {
      this.setState({
        filteredPaymentTypeOptions: paymentTypeOptions.filter(
          (option) => option.value !== 'mobile'
        ),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.timePeriodType !== this.state.timePeriodType) {
      this.props.resetTransactionDashboardData();
      if (this.state.timePeriodType === 'custom-date') {
        this.setState({
          message: 'Select the date range to view dashboard reports',
        });
      } else {
        this.setState({
          message: 'Select the interval to view dashboard reports',
        });
      }
    }
    if (
      prevState.timePeriodType !== this.state.timePeriodType ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.interval !== this.state.interval ||
      prevState.type !== this.state.type ||
      prevState.business_ids !== this.state.business_ids
    ) {
      if (this.state.timePeriodType === 'custom-date') {
        if (this.state.startDate && this.state.endDate) {
          this.setState({ hasError: false });
        } else {
          this.setState({ hasError: true });
        }
      }
      if (this.state.timePeriodType === 'fixed-period') {
        this.getAllTransactionDashboardData();
      }
    }
  }

  getAllTransactionDashboardData = () => {
    const { business_ids, interval, type, startDate, endDate } = this.state;
    const params = {
      business_ids,
      type,
    };
    const payload =
      startDate && endDate
        ? {
            ...params,
            start_datetime: moment(startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format('YYYY-MM-DD HH:mm:ss'),
            end_datetime: moment(endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format('YYYY-MM-DD HH:mm:ss'),
          }
        : {
            ...params,
            interval_type: interval.value.interval_type,
            interval_value: interval.value.interval_value,
          };
    this.props.getSummary(payload);
    this.props.getTransactions(payload);
    this.props.getSales(payload);
    this.props.getTransactionsPerHour(payload);
    this.props.getOrderTypes(payload);
    this.setState({ hasError: false, message: '' });
  };

  updateFilteredLocation = (business_ids) => {
    this.setState({ business_ids: business_ids });
  };
  updateOpenLocationModal = (isOpen) => {
    this.setState({ isLocationFilterModalOpen: isOpen });
  };
  selectOrderType = (e) => {
    this.setState({ type: e.target.value });
  };
  selectInterval = (e) => {
    const selectedItem = intervalOptions.find(
      (item) => item.value.interval_value === e.target.value.interval_value
    );
    this.setState({ interval: selectedItem });
  };

  handleDateError = (error) => {
    if (error || !(this.state.startDate && this.state.endDate)) {
      this.setState({ hasError: true });
    } else {
      this.setState({ hasError: false });
    }
  };
  render() {
    const { allBusinesses, transactionsDashboard } = this.props;
    const {
      business_ids,
      type,
      interval,
      isLocationFilterModalOpen,
      selectedLength,
      filteredPaymentTypeOptions,
    } = this.state;
    const {
      summaryData,
      transactionsData,
      salesData,
      transactionsPerHourData,
      orderTypesData,
      loading,
    } = transactionsDashboard;
    let businessOptions = [{ id: 'all', name: 'All Locations' }];
    if (allBusinesses) {
      businessOptions = [...businessOptions, ...allBusinesses];
    }
    const { startDate, endDate, hasError, message, timePeriodType } =
      this.state;
    return (
      <div>
        <Title>Transactions</Title>
        <SearchWrapper isDisabled={loading}>
          <FormControl variant='outlined' size='small'>
            <DashboardDateSelection
              timePeriodType={this.state.timePeriodType}
              setTimePeriodType={(newValue) =>
                this.setState({ timePeriodType: newValue })
              }
              startDate={startDate}
              endDate={endDate}
              setStartDate={(date) => this.setState({ startDate: date })}
              setEndDate={(date) => this.setState({ endDate: date })}
              intervalTypeAndValue={interval}
              setIntervalTypeAndValue={(newIntervalTypeAndValue) =>
                this.setState({ interval: newIntervalTypeAndValue })
              }
              height={'40px'}
              handleDateError={this.handleDateError}
            />
          </FormControl>
          <FormControl variant='outlined' size='medium'>
            <InputLabel id='demo-simple-select-outlined-label'>
              Order Type
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={type}
              onChange={this.selectOrderType}
              label='Order Type'
              style={{
                backgroundColor: 'white',
              }}
            >
              {filteredPaymentTypeOptions.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FilterButton
            message={
              business_ids.length === 0 ||
              allBusinesses.length === business_ids.length
                ? 'All Locations Selected'
                : `Selected Locations (${business_ids.length})`
            }
            setIsOpen={this.updateOpenLocationModal}
            height={'56px'}
          />
          {timePeriodType !== 'fixed-period' && (
            <ButtonWrapper>
              <Button
                onClick={this.getAllTransactionDashboardData}
                disabled={hasError}
              >
                View Stats
              </Button>
            </ButtonWrapper>
          )}
        </SearchWrapper>
        {message ? (
          <MessageWrapper>{message}</MessageWrapper>
        ) : (
          <>
            {summaryData && loading ? (
              <div>
                {summaryData ? (
                  <SummaryBar data={summaryData} />
                ) : (
                  <LoadingBlueLarge />
                )}

                <Charts>
                  {transactionsData ? (
                    <Transactions
                      data={transactionsData}
                      stateInterval={this.state.interval.value}
                      timePeriodType={this.state.timePeriodType}
                    />
                  ) : (
                    <LoadingBlueLarge />
                  )}

                  {salesData ? (
                    <Sales
                      data={salesData}
                      stateInterval={this.state.interval.value}
                      timePeriodType={this.state.timePeriodType}
                    />
                  ) : (
                    <LoadingBlueLarge />
                  )}
                </Charts>

                <Charts>
                  {transactionsPerHourData ? (
                    <TransactionsPerHour data={transactionsPerHourData} />
                  ) : (
                    <LoadingBlueLarge />
                  )}

                  {orderTypesData ? (
                    <OrderTypes data={orderTypesData} />
                  ) : (
                    <LoadingBlueLarge />
                  )}
                </Charts>
              </div>
            ) : (
              <LoadingBlueLarge />
            )}
          </>
        )}
        {isLocationFilterModalOpen && (
          <LocationFilterModal
            placeholder='Enter Internal Location Name'
            searchLabel='Search by Internal Location Name'
            filteredLocations={business_ids}
            setFilteredLocations={this.updateFilteredLocation}
            isOpen={isLocationFilterModalOpen}
            setIsOpen={this.updateOpenLocationModal}
            emptyIsEqualToAll={true}
            isEmptyAccepted={false}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactionsDashboard: state.transactionsDashboard,
    transactionsForm: state.form.TransactionsFilterSelection,
    token: state.accountReducer.token,
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  };
}

export default connect(mapStateToProps, actions)(TransactionsDashboard);
