export const posDataTransform = (products, posData) => {
  products.foreach((product) => {
    posData.foreach((pos) => {
      if (product.productId === posData.productId) {
        product.productVariants.map((variant) => {
          if (variant.variantId === posData.variantId) {
            return {
              productId: product.id,
              productName: product.name,
              productImageUrl: product.image_url,
              productIsActive: product.is_active,
              productVariants,
            };
          }
        });
      }
    });
  });
};

export const discountOptionsModified = (options, type) => {
  let optionsModified = [];
  optionsModified = options.map((option) => {
    if (type === 'credit code') {
      return {
        ...option,
        value: option.coupon_details.label,
        label:
          option.coupon_details.label + ' - ' + option.coupon_details.coupon_id,
      };
    }
    if (type === 'offer') {
      const value = option.offer_id;
      const label = option.label + ' - ' + option.offer_id;

      return {
        ...option,
        value: value,
        label: label,
      };
    }
    if (type === 'combo') {
      return {
        ...option,
        value: option.name,
        label: option.name,
      };
    }
  });
  return optionsModified;
};
