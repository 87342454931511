import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Label } from 'merchant-portal-components';
import Card from '../../../components/Card/Card';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField, InputAdornment } from '@material-ui/core';
import { MiniToggleSwitch } from '../../../components/ToggleSwitchSmall/ToggleSwitchSmall';
import FilterButton from '../../../components/FilterModal/FilterButton';
import FilterProductVariantsModal from '../../../components/FilterModal/FilterProductVariantsModal';
import { Button } from 'merchant-portal-components';

const WrapperTop = styled.div`
  .mb25 {
    margin-bottom: 25px;
    min-width: 390px;
  }
`;
const TitleMain = styled.div`
  font-size: 21px;
  font-weight: 600;
  color: #191818;
`;
const NoteTextBold = styled.div`
  font-size: 14px;
  color: #191818;
  margin-bottom: 10px;
  font-weight: 600;
`;
const WrapperInnerMain = styled.div`
  padding-left: 15px;
`;
const Wrapper = styled.div`
  display: grid;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: ${(props) =>
    props.isLastItem ? 'none' : '2px solid #000000'};
`;
const WrapperInner = styled.div`
  display: grid;
  align-items: center;
  margin-left: 30px;
`;
const Subtitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #191818;
`;
const FullWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 65px;
  align-items: center;
  margin-bottom: 15px;
  .formSection {
    display: grid;
  }
`;
const FullWrapperNonFlex = styled.div`
  display: block;
  margin-bottom: 15px;
  padding-left: 10px;

  .formSection {
    display: grid;
  }
`;
const NoteText = styled.div`
  font-size: 14px;
  color: #191818;
  margin-bottom: 10px;
`;
const NoteHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 5px;
`;
const NoteHeadNormal = styled.span`
  font-size: 17px;
  color: #191818;
  font-weight: normal;
`;
const SubHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 15px;
`;

const WrapperInside = styled.div`
  /* border-bottom: 1px solid #e1e1e1; */
  padding-bottom: 10px;
  padding-top: 10px;
  .addProduct {
    background: none;
    /* border: 0px; */
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 10px;
  }
`;
const NoteInside = styled.div`
  padding-left: 15px;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const ActionBox = styled.div`
  display: flex;
  padding-left: 15px;
  max-width: 120px;
`;

const SubnoteInside = styled.div`
  padding-left: 15px;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;
const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 50px;
  align-items: center;
`;
const FlexWrapper = styled.div`
  width: ${(props) => (props) => props.width || '110px'};
  grid-gap: 30px;
`;
const ToggleWrapper = styled.div`
  margin: 15px 0 10px 0;
  display: flex;
  gap: 7px;
`;
const FlexWrapperFloat = styled.div`
  width: ${(props) => (props) => props.width || '110px'};
  align-items: center;
`;
const AdormentIcon = styled.i`
  color: #bdbdbd;
  font-size: ${(props) => props.size || '15px'};
  margin: ${(props) => props.margin || 0};
`;
const ErrorSpan = styled.div`
  color: red;
  font-size: 12px;
`;
const CoppyButtonWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
`;
const RewardDetails = ({
  values,
  rewardOptions,
  setFieldValue,
  offerEngineProducts,
  allProductVariants,
  errors,
  title,
  indexItem,
  posMappingStringValue,
  setPosMappingStringValue,
  isLastItemInTheCard,
  isWebRequire,
  isMobileRequire,
  handleCopyRewards,
}) => {
  const currentSource = values?.conditionValue[indexItem]?.source;
  const isScanToPay = !['web', 'mobile'].includes(currentSource);
  const [isOpen, setIsOpen] = useState(false);
  const [isAllProductsSelected, setIsAllProductsSelected] = useState(false);
  const handleOpenProductVariantsModal = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    let count = 0;
    allProductVariants?.forEach((product) => {
      if (product.prices?.length > 0) {
        product.prices.forEach((variant) => count++);
      } else {
        count++;
      }
    });
    const mappedProductVariants = values.conditionValue[indexItem]?.reward
      ? values.conditionValue[indexItem]?.reward?.products
      : [];
    if (
      count === mappedProductVariants?.length ||
      mappedProductVariants?.length === 0
    ) {
      setIsAllProductsSelected(true);
    } else {
      setIsAllProductsSelected(false);
    }
  }, [values.conditionValue[0]?.reward?.products, allProductVariants]);
  const handleUpdateProducts = (products) => {
    // values.conditionValue.forEach((_, i) => {
    // });
    setFieldValue(`conditionValue[${indexItem}].reward.products`, products);
  };
  const [isAllPOSMappingIDsSelected, setIsAllPOSMappingIDsSelected] =
    useState(false);
  useEffect(() => {
    if (
      values.conditionValue.length > 0 &&
      !['web', 'mobile'].includes(values.conditionValue[indexItem]?.source)
    ) {
      let posMappingString = [];
      if (
        values.conditionValue[indexItem]?.reward.products?.length === 0 &&
        values.offer_details.template_name !== 'free-item'
      ) {
        setIsAllPOSMappingIDsSelected(true);
      } else {
        posMappingString = JSON.stringify(
          values.conditionValue[indexItem]?.reward.products?.map(
            (pro) => pro.product_id
          )
        );
        posMappingString = posMappingString?.substring(
          1,
          posMappingString.length - 1
        );
        setIsAllPOSMappingIDsSelected(false);
      }
      posMappingString =
        posMappingString && typeof posMappingString === 'string'
          ? posMappingString?.replaceAll('"', '')
          : posMappingString;
      setPosMappingStringValue(posMappingString);
    }
  }, [indexItem]);
  const handleChangePOSIDs = (e, setFieldValue, indexItem) => {
    const field = e.target.name;
    let value = e.target.value;
    // let value = e.target.value.replace(/[^0-9.,]/g, '');
    let formatedFiledValue = [];
    let filedValue = value.split(',');
    filedValue.forEach((item) => {
      formatedFiledValue.push({ product_id: item });
      // if (!isNaN(item) && !isNaN(parseFloat(item))) {
      // }
    });
    setFieldValue(field, formatedFiledValue);
    setPosMappingStringValue(value);
  };
  return (
    <WrapperTop>
      <Wrapper isLastItem={isLastItemInTheCard}>
        <TitleMain>{title}</TitleMain>
        {isWebRequire && isMobileRequire && title !== 'Scan (POS)' && (
          <CoppyButtonWrapper>
            <Button
              onClick={() =>
                handleCopyRewards(title === 'Web' ? 'mobile' : 'web')
              }
            >
              {values.conditionValue[indexItem]?.source === 'web'
                ? 'Copy From Mobile'
                : 'Copy From Web'}
            </Button>
          </CoppyButtonWrapper>
        )}
        <WrapperInside>
          {values.offer_details.template_name === 'first-time' && (
            <FullWrapper>
              <div className='formSection'>
                <NoteHead>
                  Maximum dollar amount that can be discounted per redemption
                </NoteHead>
                <FlexWrapper>
                  <TextField
                    id='outlined-basic'
                    type='number'
                    label=''
                    variant='outlined'
                    placeholder='12.00'
                    value={values.conditionValue[indexItem]?.reward?.amount}
                    onChange={(e) => {
                      // values.conditionValue.forEach((_, i) => {
                      // });
                      setFieldValue(
                        `conditionValue[${indexItem}].reward.amount`,
                        parseFloat(e.target.value)
                      );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AdormentIcon
                            className='fa fa-dollar-sign'
                            size='17px'
                          ></AdormentIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors?.conditionValue &&
                    errors?.conditionValue[indexItem]?.reward?.amount && (
                      <ErrorSpan>
                        {errors?.conditionValue[indexItem]?.reward?.amount}
                      </ErrorSpan>
                    )}
                </FlexWrapper>
              </div>
            </FullWrapper>
          )}
          {values.offer_details.template_name === 'free-item' && (
            <FullWrapper>
              <div className='formSection'>
                <NoteHead>
                  Maximum dollar amount that can be discounted per redemption
                </NoteHead>
                <FlexWrapper>
                  <TextField
                    id='outlined-basic'
                    type='number'
                    label=''
                    variant='outlined'
                    placeholder='12.00'
                    value={values.conditionValue[indexItem]?.reward?.amount}
                    onChange={(e) => {
                      // values.conditionValue.forEach((_, i) => {
                      // });
                      setFieldValue(
                        `conditionValue[${indexItem}].reward.amount`,
                        parseFloat(e.target.value)
                      );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AdormentIcon
                            className='fa fa-dollar-sign'
                            size='17px'
                          ></AdormentIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors?.conditionValue &&
                    errors?.conditionValue[indexItem]?.reward?.amount && (
                      <ErrorSpan>
                        {errors?.conditionValue[indexItem]?.reward?.amount}
                      </ErrorSpan>
                    )}
                </FlexWrapper>
              </div>
            </FullWrapper>
          )}
          {values.offer_details.template_name === 'discounted-item' && (
            <FullWrapper>
              <div className='formSection'>
                <NoteHead>Percentage discount amount</NoteHead>
                <FlexWrapperFloat width='160px'>
                  <TextField
                    id='outlined-basic'
                    type='number'
                    label=''
                    variant='outlined'
                    placeholder='30'
                    value={values.conditionValue[indexItem]?.reward?.amount}
                    onChange={(e) => {
                      setFieldValue(
                        `conditionValue[${indexItem}].reward.amount`,
                        parseFloat(e.target.value)
                      );
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <AdormentIcon
                            className='fa fa-percent'
                            margin='0 5px 0 0'
                            size='13px'
                          ></AdormentIcon>
                          <span style={{ color: '#bdbdbd' }}>off</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors?.conditionValue &&
                    errors?.conditionValue[indexItem]?.reward?.amount && (
                      <ErrorSpan>
                        {errors?.conditionValue[indexItem]?.reward?.amount}
                      </ErrorSpan>
                    )}
                </FlexWrapperFloat>
              </div>
            </FullWrapper>
          )}

          {values.offer_details.template_name === 'dollar-discount' && (
            <FullWrapper>
              <div className='formSection'>
                <NoteHead>Dollar discount amount</NoteHead>
                <FlexWrapper>
                  <TextField
                    id='outlined-basic'
                    type='number'
                    label=''
                    variant='outlined'
                    placeholder='12.00'
                    value={values.conditionValue[indexItem]?.reward?.amount}
                    onChange={(e) => {
                      // values.conditionValue.forEach((_, i) => {
                      // });
                      setFieldValue(
                        `conditionValue[${indexItem}].reward.amount`,
                        parseFloat(e.target.value)
                      );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AdormentIcon
                            className='fa fa-dollar-sign'
                            size='17px'
                          ></AdormentIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors?.conditionValue &&
                    errors?.conditionValue[indexItem]?.reward?.amount && (
                      <ErrorSpan>
                        {errors?.conditionValue[indexItem]?.reward?.amount}
                      </ErrorSpan>
                    )}
                </FlexWrapper>
              </div>
            </FullWrapper>
          )}
          {values.offer_details.template_name === 'bonus-points' && (
            <FullWrapper>
              <div className='formSection'>
                <NoteHead>Bonus points amount</NoteHead>
                <FlexWrapper width='160px'>
                  <TextField
                    id='outlined-basic'
                    type='number'
                    label=''
                    variant='outlined'
                    placeholder='12'
                    value={values.conditionValue[indexItem]?.reward?.amount}
                    onChange={(e) => {
                      // values.conditionValue.forEach((_, i) => {});
                      setFieldValue(
                        `conditionValue[${indexItem}].reward.amount`,
                        parseFloat(e.target.value)
                      );
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>points</InputAdornment>
                      ),
                    }}
                  />
                  {errors?.conditionValue &&
                    errors?.conditionValue[indexItem]?.reward?.amount && (
                      <ErrorSpan>
                        {errors?.conditionValue[indexItem]?.reward?.amount}
                      </ErrorSpan>
                    )}
                </FlexWrapper>
              </div>
            </FullWrapper>
          )}
          {values.offer_details.template_name === 'points-multiplier' && (
            <FullWrapper>
              <div className='formSection'>
                <NoteHead>Points Multiply By </NoteHead>
                <FlexWrapper width='110px'>
                  <TextField
                    id='outlined-basic'
                    type='number'
                    label=''
                    variant='outlined'
                    placeholder='e.g 2'
                    value={values.conditionValue[indexItem]?.reward?.amount}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      if (val >= 1)
                        setFieldValue(
                          `conditionValue[${indexItem}].reward.amount`,
                          parseFloat(e.target.value)
                        );
                    }}
                  />
                  {errors?.conditionValue &&
                    errors?.conditionValue[indexItem]?.reward?.amount && (
                      <ErrorSpan>
                        {errors?.conditionValue[indexItem]?.reward?.amount}
                      </ErrorSpan>
                    )}
                </FlexWrapper>
              </div>
            </FullWrapper>
          )}
          {values.offer_details.template_name === 'carryover-loyalty' && (
            <FullWrapper>
              <div className='formSection'>
                <NoteHead>
                  Maximum dollar amount that can be discounted per redemption
                </NoteHead>
                <FlexWrapper>
                  <TextField
                    id='outlined-basic'
                    type='number'
                    label=''
                    variant='outlined'
                    placeholder='12.00'
                    value={values.conditionValue[indexItem]?.reward?.amount}
                    onChange={(e) => {
                      setFieldValue(
                        `conditionValue[${indexItem}].reward.amount`,
                        parseFloat(e.target.value)
                      );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AdormentIcon
                            className='fa fa-dollar-sign'
                            size='17px'
                          ></AdormentIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors?.conditionValue &&
                    errors?.conditionValue[indexItem]?.reward?.amount && (
                      <ErrorSpan>
                        {errors?.conditionValue[indexItem]?.reward?.amount}
                      </ErrorSpan>
                    )}
                </FlexWrapper>
              </div>
            </FullWrapper>
          )}
          <div style={{ marginBottom: '20px' }}>
            {values.conditionValue[indexItem]?.reward &&
              values.offer_details.template_name !== 'first-time' &&
              values?.offer_details?.template_name !== 'carryover-loyalty' && (
                <>
                  {isScanToPay ? (
                    <>
                      <NoteHead>
                        Add POS mapping IDs of the Products that will be
                        eligible for the offer
                      </NoteHead>
                      {values.offer_details.template_name !== 'free-item' && (
                        <ToggleWrapper>
                          <MiniToggleSwitch
                            checked={isAllPOSMappingIDsSelected}
                            onChange={(event) => {
                              setIsAllPOSMappingIDsSelected(
                                event.target.checked
                              );
                              if (event.target.checked) {
                                setFieldValue(
                                  `conditionValue[${indexItem}].reward.products`,
                                  []
                                );
                              }
                            }}
                            disabled={
                              values.offer_details.template_name === 'free-item'
                            }
                          />
                          <span>Associate offer to all products</span>
                        </ToggleWrapper>
                      )}
                      {(!isAllPOSMappingIDsSelected ||
                        values.offer_details.template_name === 'free-item') && (
                        <>
                          <TextField
                            multiline
                            placeholder='Type POS IDs'
                            name={`conditionValue[${indexItem}].reward.products`}
                            value={posMappingStringValue}
                            onChange={(e) =>
                              handleChangePOSIDs(e, setFieldValue, indexItem)
                            }
                            variant='outlined'
                            style={{ width: '100%' }}
                          />
                          {values.conditionValue[indexItem]?.reward.products
                            ?.length === 0 && <ErrorSpan>Required</ErrorSpan>}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <NoteHead>
                        Select Products and variants that will be eligible for
                        the offer
                      </NoteHead>
                      <FilterButton
                        message={
                          values.offer_details.template_name !== 'free-item' &&
                          values.conditionValue[indexItem]?.reward?.products
                            ?.length === 0
                            ? 'All Product Variants Selected'
                            : `Selected Product Variants (${values.conditionValue[indexItem]?.reward?.products?.length})`
                        }
                        height='55px'
                        setIsOpen={() =>
                          handleOpenProductVariantsModal(setFieldValue)
                        }
                      />
                      {values.offer_details.template_name === 'free-item' &&
                        values.conditionValue[indexItem]?.reward?.products
                          ?.length === 0 && (
                          <ErrorSpan>
                            At least one product-variant should be selected
                          </ErrorSpan>
                        )}
                    </>
                  )}
                </>
              )}

            {isOpen && (
              <FilterProductVariantsModal
                placeholder='Enter Product/Variants Name'
                searchLabel='Search by Product/Variants Name'
                filteredProducts={
                  values.conditionValue[indexItem]?.reward?.products
                }
                setFilteredProducts={handleUpdateProducts}
                isAllProductsSelected={isAllProductsSelected}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // formField={formKey}
                emptyIsEqualToAll={
                  values.offer_details.template_name !== 'free-item'
                }
                isEmptyAccepted={false}
              />
            )}
          </div>
        </WrapperInside>
      </Wrapper>
    </WrapperTop>
  );
};
export default RewardDetails;
