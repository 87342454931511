import React, { Fragment, useRef, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Button, ButtonRow } from 'merchant-portal-components';
import Card from '../../../../../components/Card/Card';
import {
  RenderTextField,
  required,
  RenderToggleSwitch,
} from '../../../../../components/FormElements/FormikElements';
import { HrLine } from 'merchant-portal-components';
import RewardOptionsReferralForm from './RewardOptionsReferralForm';
import ConfirmCancelModal from '../../../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal';
import { StyledLabel } from 'merchant-portal-components';
import { TextField } from '@material-ui/core';

const FormWrapper = styled(Card)`
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 200px;
  grid-gap: 20px;
  padding: 20px;
`;

const FieldWrapper = styled.div`
  grid-column: span ${(props) => props.col};
  margin: ${(props) => props.margin ?? 0};
`;
const ReferralCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0 0 15px 0;
`;
const ReferralCode = styled.div`
  font-weight: 600;
`;

const InsertIcon = styled.i`
  cursor: pointer;
  color: #007bff;
  font-size: 20px;
`;

const Token = styled.div`
  display: inline-block;
  font-size: 12px;
`;
const CharacterLimitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const CharacterLimit = styled.span`
  color: grey;
  font-size: 12px;
`;

const ReferralCouponForm = (props) => {
  const {
    allBusinesses,
    programType,
    appIdentifier,
    initialValues,
    handleSubmitReferralCoupon,
    isEditingCustomer,
    setIsEditingCustomers,
    cancelCoupon,
    isReferralCoupon,
  } = props;
  const form = useRef();
  const referralMessageRef = useRef();

  useEffect(() => {
    if (form.current) {
      form.current.getFormikActions().validateForm();
    }
  });

  const [confirmCancelModalIsVisible, setConfirmCancelModalIsVisible] =
    useState(false);

  const modifiedInitialValues = useMemo(() => {
    const newInitialValues = { ...initialValues };
    if (
      initialValues.coupon_details.template_values.conditions.referee.rules
        .length === 0
    ) {
      initialValues.coupon_details.template_values.conditions.referee.rules = {
        min_order: 0,
        purchase_by_referee: false,
      };
    }
    if (
      initialValues.coupon_details.template_values.conditions.referrer.rules
        .length === 0
    ) {
      initialValues.coupon_details.template_values.conditions.referrer.rules = {
        min_order: 0,
        purchase_by_referee: false,
      };
    }
    return newInitialValues;
  }, [initialValues]);
  const insertTextAtCursor = (setFieldValue, currMessage) => {
    const input = referralMessageRef.current;
    const fieldName = 'coupon_details.referral_message';
    if (input) {
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const currentValue = currMessage;
      const newValue =
        currentValue.slice(0, start) +
        '$$REFERRAL_CODE$$' +
        currentValue.slice(end);
      setFieldValue(fieldName, newValue);
    }
  };
  return (
    <Fragment>
      <Formik
        initialValues={modifiedInitialValues}
        enableReinitialize={true}
        ref={form}
      >
        {({
          values,
          handleReset,
          setFieldValue,
          errors,
          isSubmitting,
          dirty,
        }) => (
          <FormWrapper>
            <Form>
              <ContentWrapper>
                <FieldWrapper col={4}>
                  <Field
                    name='coupon_details.is_active'
                    label='Active'
                    component={RenderToggleSwitch}
                    onChange={(e) => {
                      setFieldValue(
                        'coupon_details.is_active',
                        e.target.checked
                      );
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper col={2}>
                  <Field
                    name='coupon_details.label'
                    component={RenderTextField}
                    label='Ref Coupon Name *'
                    validate={required}
                    onChange={(e) => {
                      // setIsEditingCustomers(true);
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper col={2}></FieldWrapper>
                <FieldWrapper col={4}>
                  <Field
                    name='coupon_details.description'
                    label={
                      appIdentifier != 'toppers'
                        ? 'Coupon Description *'
                        : 'Coupon Description'
                    }
                    component={RenderTextField}
                    rows={3}
                    validate={() => {
                      if (
                        appIdentifier != 'toppers' &&
                        !values.coupon_details.description
                      )
                        return 'Required';
                    }}
                    type='text'
                    onChange={(e) => {
                      // setIsEditingCustomers(true);
                    }}
                  />
                </FieldWrapper>
                {isReferralCoupon && (
                  <FieldWrapper col={4} margin='10px 0 0 0'>
                    <ReferralCodeWrapper>
                      <ReferralCode>
                        Click
                        <InsertIcon
                          className='fa fa-file-import'
                          onClick={(e) => {
                            e.preventDefault();
                            insertTextAtCursor(
                              setFieldValue,
                              values.coupon_details.referral_message
                            );
                          }}
                          style={{ margin: '0 5px' }}
                        ></InsertIcon>
                        to insert your referral code placeholder
                        ($$REFERRAL_CODE$$) into your message.
                      </ReferralCode>
                    </ReferralCodeWrapper>
                    <TextField
                      inputRef={referralMessageRef}
                      variant='outlined'
                      multiline
                      rows={3}
                      name='coupon_details.referral_message'
                      value={values.coupon_details.referral_message}
                      label='Referral Message (max 1000)'
                      onChange={(e) => {
                        setFieldValue(
                          'coupon_details.referral_message',
                          e.target.value
                        );
                      }}
                      required
                      style={{ width: '100%' }}
                    />
                    <CharacterLimitWrapper>
                      <CharacterLimit>{`${values.coupon_details.referral_message.length} / 1000`}</CharacterLimit>
                    </CharacterLimitWrapper>
                  </FieldWrapper>
                )}
                <FieldWrapper col={4}>
                  <HrLine />
                </FieldWrapper>

                <RewardOptionsReferralForm
                  values={values}
                  programType={programType}
                  setFieldValue={setFieldValue}
                  allBusinesses={allBusinesses}
                  setIsEditingCustomers={setIsEditingCustomers}
                />

                <FieldWrapper col={4}>
                  <StyledLabel>Referee Reward Conditions</StyledLabel>
                </FieldWrapper>

                <FieldWrapper col={4}>
                  <Field
                    name='coupon_details.template_values.conditions.referee.rules.purchase_by_referee'
                    label='Purchase required by referee'
                    component={RenderToggleSwitch}
                    onChange={(e) => {
                      setFieldValue(
                        'coupon_details.template_values.conditions.referee.rules.purchase_by_referee',
                        e.target.checked
                      );
                      setFieldValue(
                        'coupon_details.template_values.conditions.referee.rules.min_order',
                        !e.target.checked ? 0 : ''
                      );
                    }}
                  />
                </FieldWrapper>

                <FieldWrapper col={1}>
                  <Field
                    name='coupon_details.template_values.conditions.referee.rules.min_order'
                    label='Minimum order amount *'
                    component={RenderTextField}
                    type='number'
                    min='0'
                    disabled={
                      !values.coupon_details.template_values.conditions.referee
                        .rules.purchase_by_referee
                    }
                    validate={required}
                    onChange={(e) => {
                      setFieldValue(
                        'coupon_details.template_values.conditions.referee.rules.min_order',
                        e.target.value
                      );
                    }}
                  />
                </FieldWrapper>

                <FieldWrapper col={4}>
                  <StyledLabel>Referrer Reward Conditions</StyledLabel>
                </FieldWrapper>
                <FieldWrapper col={4}>
                  <Field
                    name='coupon_details.template_values.conditions.referrer.rules.purchase_by_referee'
                    label='Purchase required by referee'
                    component={RenderToggleSwitch}
                    onChange={(e) => {
                      setFieldValue(
                        'coupon_details.template_values.conditions.referrer.rules.purchase_by_referee',
                        e.target.checked
                      );
                      setFieldValue(
                        'coupon_details.template_values.conditions.referrer.rules.min_order',
                        !e.target.checked ? 0 : ''
                      );
                    }}
                  />
                </FieldWrapper>

                <FieldWrapper col={1}>
                  <Field
                    name='coupon_details.template_values.conditions.referrer.rules.min_order'
                    label='Minimum order amount *'
                    component={RenderTextField}
                    type='number'
                    min='0'
                    disabled={
                      !values.coupon_details.template_values.conditions.referrer
                        .rules.purchase_by_referee
                    }
                    validate={required}
                    onChange={(e) => {
                      setFieldValue(
                        'coupon_details.template_values.conditions.referrer.rules.min_order',
                        e.target.value
                      );
                    }}
                  />
                </FieldWrapper>
              </ContentWrapper>
              <ButtonRow
                justifyContent='space-between'
                style={{
                  margin: '24px 40px 24px 40px',
                  width: 'inherit',
                }}
              >
                <Button
                  style={{ outline: 'none' }}
                  secondary
                  onClick={() => {
                    isEditingCustomer
                      ? setConfirmCancelModalIsVisible(true)
                      : setConfirmCancelModalIsVisible(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type='button'
                  style={{ outline: 'none' }}
                  disabled={
                    !dirty ||
                    Object.keys(errors).length ||
                    (isReferralCoupon &&
                      !values.coupon_details.referral_message)
                  }
                  onClick={() => {
                    handleSubmitReferralCoupon(values);
                  }}
                >
                  {'Save'}
                </Button>
              </ButtonRow>
            </Form>
            {confirmCancelModalIsVisible && (
              <ConfirmCancelModal
                onCancel={() => setConfirmCancelModalIsVisible(false)}
                onConfirm={() => {
                  handleReset();
                  setConfirmCancelModalIsVisible(false);
                  cancelCoupon();
                }}
              />
            )}
          </FormWrapper>
        )}
      </Formik>
    </Fragment>
  );
};

export default ReferralCouponForm;
