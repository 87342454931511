import React, { Component } from 'react';
import * as actions from './actions';
import * as selectors from './selectors';
import { connect } from 'react-redux';
import { getOffersEngine } from '../../OfferEngine/OfferEngineActions';
function getOffers(WrappedComponent) {
  class Container extends Component {
    componentDidMount() {
      this.props.getOffersEngine({
        page_number: 1,
        page_size: '1000',
        sorting_option: 'title-asc',
        search_string: '',
        has_next_page: true,
      });
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.token && this.props.token) {
        this.props.getOffersEngine({
          page_number: 1,
          page_size: '1000',
          sorting_option: 'title-asc',
          search_string: '',
          has_next_page: true,
        });
      }
    }

    render() {
      return (
        <WrappedComponent
          requestingOffers={this.props.requestingOffers}
          allOffers={this.props.allOffers}
          {...this.props}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      allOffers: state.OfferEngineReducer.allOffersEngine,
      requestingOffers: state.offers.requestingOffers,
      token: state.accountReducer.token,
    };
  }

  return connect(mapStateToProps, { ...actions, getOffersEngine })(Container);
}

export default getOffers;
