import styled from 'styled-components';
export const DisabledWrapper = styled.div`
  background-color: ${(props) => (props.disabled ? '#f2f2f2' : 'transparent')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  border-radius: ${(props) => (props.disabled ? '10px' : 0)};
  padding: ${(props) => (props.disabled ? props.margin ?? '10px' : 0)};
  margin: ${(props) => (props.disabled ? props.margin ?? '10px 0px' : 0)};
`;

export const PremiumFeature = styled.div`
  font-weight: 400;
  font-style: italic;
  margin: 10px 0;
  &::before {
    content: 'Premium Account Only';
  }
`;
