import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import exportCSVWrapper from '../HOCs/exportCSVWrapper';
import * as actions from './summaryReportActions';
import SummaryReportList from './SummaryReportList/SummaryReportList';
import SummaryReportDetail from './SummaryReportDetail/SummaryReportDetail';
import styled from 'styled-components';
const Wrapper = styled.div`
  max-width: 100%;
  margin: auto;
  overflow-x: auto;
`;
const exportHeaders = [
  { label: 'Business Name', key: 'display_name' },
  { label: 'Start Period', key: 'start_date' },
  { label: 'End Period', key: 'end_date' },
  { label: 'Gross Sales', key: 'gross_sales' },
  { label: 'Adjusted Gross Sales', key: 'adjusted_gross' },
  { label: 'Net Sales', key: 'net_sales' },
  { label: 'Expected Deposit', key: 'expected_deposit' },
  { label: 'Subtotal', key: 'subtotal' },
  { label: 'Fees', key: 'fees' },
  { label: 'Tax', key: 'tax' },
  { label: 'Tip', key: 'tip' },
  { label: 'Payments by Credit Card', key: 'amount_collected_credit_card' },
  { label: 'Payments by Gift Card', key: 'amount_collected_gift_card' },
  { label: 'Payment by Smooth', key: 'amount_collected_in_smooth' },
  { label: 'Payments by Paypal', key: 'amount_collected_paypal' },
  { label: 'Refunds By Credit Card', key: 'amount_refunded_credit_card' },
  { label: 'Refunds by Gift Card', key: 'amount_refunded_gift_card' },
  { label: 'Refunds by PayPal', key: 'amount_refunded_paypal' },
  { label: 'External Payments', key: 'external_payment' },
  { label: 'Refund External Payments', key: 'refund_external_payment' },
  { label: 'Refund Payments', key: 'refund_payment' },
  { label: 'Total Credit Applied', key: 'total_credit_applied' },
  { label: 'Total Credit Refunded', key: 'total_credit_refunded' },
  { label: 'Total Refund', key: 'total_refund' },
];
let SummaryReportContainer = ({
  allBusinesses,
  allOrderSummaries,
  requestParams,
  getOrderSummaries,
  clearAllOrderSummaries,
  orderSource,
  orderTypesList,
  updateRequestParams,
}) => {
  const [modifiedOrderSource, setModifiedOrderSource] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  // locations
  const [selectedBusinessesIDs, setSelectedBusinessesIDs] = useState([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [formatedBusinesses, setFormatedBusinesses] = useState([]);
  // order source
  const [formatedOrderSources, setFormatedOrderSources] = useState([]);
  const [selectedOrderSources, setSelectedOrderSources] = useState([]);
  const [selectedOrderSourcesValues, setSelectedOrderSourcesValues] = useState(
    []
  );
  // order Type
  const [formatedOrderTypes, setFormatedOrderTypes] = useState([]);
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);
  const [selectedOrderTypesValues, setSelectedOrderTypesValues] = useState([]);
  // Peyment Type
  const [paymentTypesList, setPaymentTypesList] = useState();
  const [formatedPaymentTypes, setFormatedPaymentTypes] = useState([]);
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
  const [selectedPaymentTypesValues, setSelectedPaymentTypesValues] = useState(
    []
  );
  //
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [maxDueDate, setMaxDueDate] = useState(new Date());
  const [exprotData, setExportData] = useState(null);
  const [errorMessage, seterrorMessage] = useState();
  const [selectedLocationSummary, setSelectedLocationSummary] = useState(null);
  const [totalCheckbox, setTotalCheckbox] = useState(false);
  const [checkboxList, setCheckboxList] = useState([]);
  useEffect(() => {
    let newOrderSource = orderSource && [...orderSource];
    let newPaymentTypesList = [{ id: 1, type: 'In-App' }];
    let hasInStore = false;
    orderSource?.forEach((source) => {
      if (source.value !== 'web' && source.value !== 'mobile') {
        hasInStore = true;
      }
    });
    orderTypesList?.forEach((type) => {
      if (type.is_loyalty_only_enabled) {
        hasInStore = true;
      }
    });
    if (hasInStore) {
      newPaymentTypesList.push({ id: 1, type: 'In-Store' });
    }
    /// update order sorce list (to cover sodexo)
    const appMetaData = JSON.parse(localStorage.getItem('appMetaData'));
    const isScanToPayEnabled =
      appMetaData?.configuration?.is_scan_to_pay_enabled;
    const filteredOrderSource = orderSource?.filter(
      (source) =>
        source.label.toLowerCase() !== 'web' &&
        source.label.toLowerCase() !== 'mobile' &&
        source.label.toLowerCase() !== 'generic'
    );
    if (filteredOrderSource?.length === 0 && isScanToPayEnabled) {
      newOrderSource.push({ label: 'scan', value: 'scan' });
    }
    setModifiedOrderSource(newOrderSource);
    setPaymentTypesList(newPaymentTypesList);
  }, [orderSource, orderTypesList]);
  useEffect(() => {
    return () => {
      clearAllOrderSummaries();
    };
  }, []);

  const clearSelectedDates = () => {
    setStartDate(null);
    setEndDate(null);
  };
  return (
    <Wrapper>
      {selectedLocationSummary ? (
        <SummaryReportDetail
          selectedLocationSummary={selectedLocationSummary}
          startDate={startDate}
          endDate={endDate}
          setSelectedLocationSummary={setSelectedLocationSummary}
          exportHeaders={exportHeaders}
          exportFileName={'Transaction_Summaries_single'}
        />
      ) : (
        <div>
          <SummaryReportList
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            clearSelectedDates={clearSelectedDates}
            allBusinesses={allBusinesses}
            requestParams={requestParams}
            getOrderSummaries={getOrderSummaries}
            allOrderSummaries={allOrderSummaries}
            setSelectedLocationSummary={setSelectedLocationSummary}
            exportHeaders={exportHeaders}
            checkboxList={checkboxList}
            setTotalCheckbox={setTotalCheckbox}
            setCheckboxList={setCheckboxList}
            totalCheckbox={totalCheckbox}
            selectedBusinessesIDs={selectedBusinessesIDs}
            setSelectedBusinessesIDs={setSelectedBusinessesIDs}
            selectedBusinesses={selectedBusinesses}
            setSelectedBusinesses={setSelectedBusinesses}
            formatedBusinesses={formatedBusinesses}
            setFormatedBusinesses={setFormatedBusinesses}
            maxDueDate={maxDueDate}
            setMaxDueDate={setMaxDueDate}
            exprotData={exprotData}
            setExportData={setExportData}
            errorMessage={errorMessage}
            seterrorMessage={seterrorMessage}
            updateRequestParams={updateRequestParams}
            // order source
            orderSource={modifiedOrderSource}
            formatedOrderSources={formatedOrderSources}
            setFormatedOrderSources={setFormatedOrderSources}
            selectedOrderSources={selectedOrderSources}
            setSelectedOrderSources={setSelectedOrderSources}
            selectedOrderSourcesValues={selectedOrderSourcesValues}
            setSelectedOrderSourcesValues={setSelectedOrderSourcesValues}
            // order Type
            orderTypesList={orderTypesList}
            formatedOrderTypes={formatedOrderTypes}
            setFormatedOrderTypes={setFormatedOrderTypes}
            selectedOrderTypes={selectedOrderTypes}
            setSelectedOrderTypes={setSelectedOrderTypes}
            selectedOrderTypesValues={selectedOrderTypesValues}
            setSelectedOrderTypesValues={setSelectedOrderTypesValues}
            // payment type
            paymentTypesList={paymentTypesList}
            formatedPaymentTypes={formatedPaymentTypes}
            setFormatedPaymentTypes={setFormatedPaymentTypes}
            selectedPaymentTypes={selectedPaymentTypes}
            setSelectedPaymentTypes={setSelectedPaymentTypes}
            selectedPaymentTypesValues={selectedPaymentTypesValues}
            setSelectedPaymentTypesValues={setSelectedPaymentTypesValues}
            filteredLocations={filteredLocations}
            setFilteredLocations={setFilteredLocations}
          />
        </div>
      )}
    </Wrapper>
  );
};

function mapStateToProps(state) {
  const appIdentifier = localStorage.getItem('appIdentifier');
  return {
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
    allOrderSummaries: state.summaryReportReducer.allOrderSummaries,
    requestParams: state.summaryReportReducer.requestParams,
    orderSource: state.offersReducer.options,
    temperoaryShowSummaryReportBanner:
      state.summaryReportReducer.temperoaryShowSummaryReportBanner,
    orderTypesList: state.fullReducer.orderTypesList,
  };
}

SummaryReportContainer = exportCSVWrapper(SummaryReportContainer);

export default connect(mapStateToProps, actions)(SummaryReportContainer);
