import React, { useEffect } from 'react';
import MobileMessageDetails from './components/MobileMessageDetails';
import moment from 'moment';
import {
  getActionArray,
  getActionValue,
  transformToFormValues,
} from '../utils';

const MobileMessageDetailsContainer = (props) => {
  const {
    mobileMessagesReducer,
    appIdentifier,
    cancelMobileMessage,
    editMobileMessage,
    createMobileMessage,
    createTestMessage,
  } = props;

  const {
    isEditOnlyMobileMessage,
    isReadOnlyMobileMessage,
    isCreating,
    initialFormValues,
  } = mobileMessagesReducer;
  useEffect(() => {
    if (isEditOnlyMobileMessage) {
      track.page('engagement-mobile-messages-edit');
    } else {
      track.page('engagement-mobile-messages-create');
    }
  }, []);

  const allProducts = props.allProducts.filter(
    (product) => product.is_active == true
  );
  const allSegments = props.allSegments.filter(
    (segment) => segment.is_active == true
  );

  const allCombos = props.allCombos.filter((combo) => combo.is_active == true);

  const allOfferDetails = props.allOffers.filter(
    (offer) => offer.is_active == true
  );

  const actionArray = getActionArray(initialFormValues);

  const actionValue = getActionValue(initialFormValues);

  const initialValuesToForm = transformToFormValues({
    initialFormValues,
    actionArray,
    actionValue,
    allProducts,
    allOfferDetails,
  });

  const onhandleSubmit = (otherProps, formProps) => {
    const send_at = formProps.values.send_now
      ? null
      : moment(formProps.values.send_at).format('YYYY-MM-DD HH:mm:ss');
    let mobileMessageData = {
      name: formProps.values.name,
      type: formProps.values.type,
      segments: formProps.values.segments,
      message: formProps.values.message,
      action_url: formProps.values.action_url,
      send_now: formProps.values.send_now,
    };
    if (send_at) {
      mobileMessageData.send_at = send_at;
    }
    const messageId = formProps.values.id;

    if (isEditOnlyMobileMessage) {
      editMobileMessage(mobileMessageData, messageId);
    } else {
      createMobileMessage(mobileMessageData);
    }
  };

  return (
    <MobileMessageDetails
      edit={isEditOnlyMobileMessage}
      read={isReadOnlyMobileMessage}
      isCreating={isCreating}
      appIdentifier={appIdentifier}
      initialFormValues={initialValuesToForm}
      allProducts={allProducts}
      allSegments={allSegments}
      allOfferDetails={allOfferDetails}
      allCombos={allCombos}
      cancelMobileMessage={cancelMobileMessage}
      editMobileMessage={editMobileMessage}
      createMobileMessage={createMobileMessage}
      createTestMessage={createTestMessage}
      onhandleSubmit={onhandleSubmit}
    />
  );
};

export default MobileMessageDetailsContainer;
