import React, { createContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Create a context
export const CustomizationContext = createContext();

// Provider component to wrap around your app
function ClientProvider({ children, appIdentifier }) {
  const offerPremiumClients = [
    'mary_browns',
    'harvestcleaneats',
    'daily_ritual_cafe',
    'good_earth',
    'double_pizza',
    'bigwheelburger',
    'burritoboyz',
    'smooth_cafe',
    'iq',
    'iQ',
    'villagejuicery',
  ];
  const multipleAppFeedItemClients = ['deloitte'];
  const orderManagementClients = [
    'feastify',
    'basilbox_catering',
    'onekingwest_amenities',
  ];
  const parentChildClients = ['feastify'];
  const exportReportClient = ['mary_browns', 'onekingwest_amenities'];
  const brinkClients = ['madradish', 'chillipeppers', 'good_earth'];

  const storedAppIdentifier = localStorage.getItem('appIdentifier');
  const [customization, setCustomization] = useState();

  useEffect(() => {
    const currentAppIdentifier = appIdentifier || storedAppIdentifier;
    const newCustomization = {
      isMaryBrowns: currentAppIdentifier === 'mary_browns',
      isParentChildClient: parentChildClients.includes(currentAppIdentifier),
      isMultipleAppFeedItemClient:
        multipleAppFeedItemClients.includes(currentAppIdentifier),
      isOrderManagementClient:
        orderManagementClients.includes(currentAppIdentifier),
      isExportReportClient: exportReportClient.includes(currentAppIdentifier),
      isBrinkClient: brinkClients.includes(currentAppIdentifier),
      isOfferPremiumClient: offerPremiumClients.includes(currentAppIdentifier),
    };
    setCustomization(newCustomization);
  }, [appIdentifier]);

  return (
    <CustomizationContext.Provider value={{ ...customization }}>
      {children}
    </CustomizationContext.Provider>
  );
}

function mapStateToProps(state) {
  return {
    appIdentifier: state.accountReducer.appIdentifier,
  };
}

const ClientCustomizationsProvider = connect(mapStateToProps)(ClientProvider);
export { ClientCustomizationsProvider };
