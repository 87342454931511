import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Chip } from '@material-ui/core';
import { CustomizationContext } from '../../../utils/ClientCustomizationsProvider';
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
`;
const ChipWrapper = styled.div`
  padding: 0 8px 10px 8px;
`;
const FilterList = ({
  initialFilters,
  filters,
  setFilters,
  setSelectedChild,
  setSelectedParent,
}) => {
  const { isParentChildClient } = useContext(CustomizationContext);
  const [newFilters, setNewFilters] = useState([]);
  const updateFilterList = useCallback(() => {
    let newFilterList = [];
    if (filters.isNew) {
      newFilterList.push({ type: 'isNew', label: 'New Order' });
    }
    if (filters.isInProgress) {
      newFilterList.push({ type: 'isInProgress', label: 'In Progress Order' });
    }
    if (filters.isFuture) {
      newFilterList.push({ type: 'isFuture', label: 'Future Order' });
    }
    if (filters.orderType === 'pickup') {
      newFilterList.push({ type: 'orderType', label: 'Pickup' });
    }
    if (filters.orderType === 'delivery') {
      newFilterList.push({ type: 'orderType', label: 'Delivery' });
    }
    if (filters.business_ids?.length > 0) {
      filters.business_ids.forEach((business) => {
        newFilterList.push({
          type: 'business_ids',
          id: isParentChildClient ? business.subBusinessId : business.id,
          label: isParentChildClient
            ? business.subBusinessName
            : business.display_name,
        });
      });
    }
    setNewFilters(newFilterList);
  }, [filters]);
  useEffect(() => {
    updateFilterList();
  }, [updateFilterList]);
  const handleDeleteFilter = (deletedFilter) => {
    const newFilters = { ...filters };
    if (deletedFilter.type !== 'business_ids') {
      if (deletedFilter.type === 'orderType') {
        newFilters[deletedFilter.type] = 'all';
      } else {
        newFilters[deletedFilter.type] = false;
      }
    } else {
      const newBusinessIDs = newFilters.business_ids.filter((business) =>
        isParentChildClient
          ? business.subBusinessId !== deletedFilter.id
          : business.id !== deletedFilter.id
      );
      newFilters[deletedFilter.type] = newBusinessIDs;
      setSelectedChild(newBusinessIDs);
    }
    setFilters(newFilters);
  };
  const handleClearAll = () => {
    setFilters(initialFilters);
    setSelectedParent([]);
    setSelectedChild([]);
  };
  return (
    <Wrapper>
      {newFilters.map((filter) => (
        <ChipWrapper>
          <Chip
            label={filter.label}
            onDelete={() => handleDeleteFilter(filter)}
          />
        </ChipWrapper>
      ))}
      {(filters.business_ids.length > 0 ||
        filters.isNew ||
        filters.isInProgress ||
        filters.isFuture) && (
        <ChipWrapper>
          <Chip label='Clear All' onDelete={handleClearAll} />
        </ChipWrapper>
      )}
    </Wrapper>
  );
};

export default FilterList;
