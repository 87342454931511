import React, { Component } from 'react';
import { Select } from 'merchant-portal-components';
class ListSearchSelect extends Component {
  state = {
    queryString: '',
    appIdentifier: localStorage.getItem('appIdentifier'),
  };
  handleSearchChange = (queryString) => {
    this.setState({ queryString });
    this.props.changed && this.props.changed(true);
  };

  renderNoOptionsMessage = () => {
    if (this.props.requestingGetListByQuery) {
      return 'Loading...';
    } else {
      return 'No results found';
    }
  };

  returnOptions = () => {
    const { listKey, allProducts, allOffers, allCombos, allBusinesses } =
      this.props;
    if (listKey === 'product') {
      return allProducts ? allProducts : [];
    } else if (listKey === 'offer') {
      return allOffers ? allOffers : [];
    } else if (listKey === 'combo') {
      return allCombos ? allCombos : [];
    } else if (listKey === 'business') {
      return allBusinesses ? allBusinesses : [];
    }
  };

  getOptionLabel = (option) => {
    const { listKey } = this.props;
    switch (listKey) {
      case 'product':
        return option.name;
      case 'combo':
        return option.name;
      case 'offer':
        return option.title;
      case 'business':
        return option.display_name;
    }
  };

  getOptionValue = (option) => {
    const { listKey } = this.props;
    switch (listKey) {
      case 'product':
        return option.id;
      case 'combo':
        return option.id;
      case 'offer':
        return option.offer_id;
      case 'business':
        return option.id;
    }
  };

  getValue = () => {
    const {
      listKey,
      allProducts,
      allCombos,
      allOffers,
      selectedItem,
      allBusinesses,
    } = this.props;

    switch (listKey) {
      case 'product':
        return (
          allProducts && allProducts.find(({ id }) => id == selectedItem.id)
        );
      case 'combo':
        return allCombos && allCombos.find(({ id }) => id == selectedItem.id);
      case 'offer':
        return (
          allOffers &&
          allOffers.find((offer) => offer.offer_id === selectedItem.id)
        );
      case 'business':
        return (
          allBusinesses && allBusinesses.find(({ id }) => id == selectedItem.id)
        );
    }
  };

  render() {
    const {
      listKey,
      selectedItem,
      selectItem,
      requestingGetProducts,
      requestingGetOffers,
      requestingGetCombos,
      light,
      allOffers,
    } = this.props;
    const isLoading =
      requestingGetProducts || requestingGetCombos || requestingGetOffers;
    return (
      <Select
        options={this.returnOptions()}
        getOptionValue={(option) => option}
        getOptionLabel={(option) => this.getOptionLabel(option)}
        isLoading={isLoading}
        noOptionsMessage={this.renderNoOptionsMessage()}
        isSearchable={true}
        inputValue={this.state.queryString}
        onChange={(selectedOption) => selectItem(selectedOption)}
        onInputChange={(queryString) => this.handleSearchChange(queryString)}
        value={selectedItem}
        placeholder={`Search ${listKey}s by name...`}
        light={light}
      />
    );
  }
}

export default ListSearchSelect;
