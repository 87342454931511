import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Table, TBody, TrHead, Td, Tr, Th } from 'merchant-portal-components';
import { withRouter } from 'react-router-dom';

const TableWrapper = styled.div`
  overflow-x: scroll;
`;

const NoLoyaltyRewards = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 250px;
  font-size: 38px;
  color: #d8d8d8;
`;

const renderHeaders = () => (
  <TrHead data-test='component-TrHead'>
    <Th align='left'>Date and Time (UTC)</Th>
    <Th align='left'>Order Number</Th>
    <Th align='left'>Amount</Th>
    <Th align='left' style={{ wordBreak: 'break-word' }}>
      Notes
    </Th>
  </TrHead>
);

const handleInnerClick = (orderId, history) => {
  history.push(`/order-history/${orderId}`);
};

const renderRows = (loyaltyPointsAccumulation, history) =>
  loyaltyPointsAccumulation.map((loyaltyPointAccumulation, index) => (
    <Tr
      // onClick={() =>
      //   handleInnerClick(`${loyaltyPointAccumulation.order_id}`, history)
      // }
      key={index}
      data-test='component-Tr'
    >
      <Td align='left'>{loyaltyPointAccumulation.created_at}</Td>
      <Td align='left'>
        {loyaltyPointAccumulation.order_id != 0
          ? loyaltyPointAccumulation.order_id
          : 'Did not come from an order'}
      </Td>
      <Td align='left'>{loyaltyPointAccumulation.points_earned}</Td>
      <Td align='left'>{loyaltyPointAccumulation.comment}</Td>
    </Tr>
  ));

export const CustomerLoyaltyRewardsTable = (props) => {
  const { loyaltyPointsAccumulation, history } = props;
  return (
    <Fragment>
      <Table data-test='component-Table'>
        <thead>{renderHeaders()}</thead>
        {loyaltyPointsAccumulation.length ? (
          <TBody striped>
            {renderRows(loyaltyPointsAccumulation, history)}
          </TBody>
        ) : null}
      </Table>
      {!loyaltyPointsAccumulation.length ? (
        <NoLoyaltyRewards data-test='component-NoLoyaltyRewards'>
          No Rewards have been sent yet
        </NoLoyaltyRewards>
      ) : null}
    </Fragment>
  );
};

const CustomerLoyaltyRewardsTableWithRouter = withRouter(
  CustomerLoyaltyRewardsTable
);

export default CustomerLoyaltyRewardsTableWithRouter;
